import LogoPng from "./module/LogoPng"

const HeaderProfile: React.FC = () => {
  return (
    <div className=''>
      <LogoPng size={150} />
    </div>
  )
}

export default HeaderProfile
